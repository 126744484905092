import './App.css';
import { DragDropContext } from 'react-beautiful-dnd';
import EditableList from './components/EditableList';
import { ToastContainer, toast } from 'react-toastify';

function App() {
  return (
    <div className="App">
      <DragDropContext>
        <EditableList />
      </DragDropContext>
    </div>
  );
}

export default App;
