import React, { useState, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import SearchModal from './SearchModal';
import StatusModal from './StatusModal';
import { Button } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import SongCard from './SongCard';
import axios from 'axios';
import { faI } from '@fortawesome/free-solid-svg-icons';

export default function EditableList() {
    const [items, setItems] = useState([]);
    const [show, setShow] = useState(false);
    const [showStatus, setShowStatus] = useState(false);
    const [snapshotID, setSnapshotID] = useState('')
    const [statusMessage, setStatusMessage] = useState('')
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCloseStatus = () => setShowStatus(false);
    const handleShowStatus = () => setShowStatus(true);

    const azure_url = "https://weddingsongapp-gsfbgncvbzdye5e9.canadaeast-01.azurewebsites.net"
    const local_url = "https://wedding-api.blustery.day"
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(local_url + '/playlist');
                setSnapshotID(response.data[0].snapshotID)
                setItems(response.data); // Assuming "tracks" key holds the data
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    // Add, remove, and reorder functions will go here
    function handleAddItem(uri, artist, title, image) {
        // Check if the URI already exists in the items array
        const itemExists = items.some(item => item.uri === uri);

        // If the URI is found, return early without modifying items
        if (itemExists) {
            return;
        }

        setItems([...items, {
            "uri": uri,
            "artist": artist,
            "image": image,
            "title": title,
            "snapshotID": snapshotID
        }]);
    }

    function handleRemoveItem(index) {
        const updatedItems = [...items]; // Create a copy of the items array
        updatedItems.splice(index, 1); // Remove the item at the specified index
        setItems(updatedItems)
    }

    const success = () => toast.success("Songs submitted successfully!");
    const fail = () => toast.error("An error occurred while submitting songs!");

    function handleSubmit() {
        const jsonData = JSON.stringify(items); // Convert to JSON string
        console.log("json data: " + jsonData)
        axios.post(`${local_url}/playlist/tracks`, jsonData, {
            headers: {
                'Content-Type': 'application/json'
            },
            params: {
                'snapshotID': snapshotID
            }
        })
            .then(response => {
                setStatusMessage("Songs submitted successfully!")
                setShowStatus(true)
                console.log("Submitted Songs:", response.data);
                window.location.reload();
            })
            .catch(error => {
                setStatusMessage("An error occurred while submitting songs")
                setShowStatus(true)
                console.error("Error submitting songs:", error);
                // window.location.reload();
            });
    }

    const handleDragEnd = (result) => {
        if (!result.destination) return; // Item dropped outside the list

        const reorderedItems = [...items]; // Create a copy of the items list
        const [movedItem] = reorderedItems.splice(result.source.index, 1); // Remove the moved item

        reorderedItems.splice(result.destination.index, 0, movedItem); // Insert the moved item at the new position

        setItems(reorderedItems);
    };

    return (
        <div className='container-fluid mt-3'>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="editable-list">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef} className="list-group">
                            {items.map((item, index) => (
                                <Draggable key={item.title + index} draggableId={item.title} index={index}>
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className="list-group-item"
                                        >
                                            <SongCard
                                                artist={item.artist}
                                                song={item.title}
                                                albumArt={item.image}
                                                provided={provided} // Pass provided props to SongCard
                                                callback={handleRemoveItem}
                                                index={index}
                                                showDelete={true}
                                                uri={item.uri}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Button style={{ marginRight: '20px' }} variant="secondary" onClick={handleShow}>
                    Add a Song
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Submit Changes
                </Button>
                <SearchModal show={show} handleClose={handleClose} handleAddItem={handleAddItem} />
                <StatusModal show={showStatus} handleClose={handleCloseStatus} message={statusMessage} />
            </div>
        </div>
    );
}