import React, { useState } from 'react';
import { Modal, Button, Form, Spinner, ListGroup, Pagination } from 'react-bootstrap';
import axios from 'axios';
import Card from 'react-bootstrap/Card';

const StatusModal = ({ show, handleClose, message }) => {

    return (
        <Modal show={show} onHide={handleClose()}>
            <Modal.Header>{message}</Modal.Header>
        </Modal>
    );
};

export default StatusModal;
