import React, { useState } from 'react';
import { Modal, Button, Form, Spinner, ListGroup, Pagination } from 'react-bootstrap';
import axios from 'axios';
import Card from 'react-bootstrap/Card';

const styles = {
    card: {
        borderRadius: 25,
        padding: '1rem',
        width: 'auto',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column'
    },
    cardImage: {
        objectFit: 'cover',
        borderRadius: 10,
        width: 'auto',
        height: '100px'
    },
    cardBody: {
        display: 'flex',
        flexDirection: 'row',
        padding: '1rem',
        alignItems: 'center',
        gap: '1rem'
    },
    cardHeader: {
        textAlign: 'left',
        marginBottom: '1rem',
        fontSize: '26px' // Increase font size for card text
    },
    cardText: {
        fontSize: '15px' // Increase font size for card text
    }
};

const SearchModal = ({ show, handleClose, handleAddItem }) => {
    const [songTitle, setSongTitle] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [searchedSongs, setSearchedSongs] = useState([])
    const [currentPage, setCurrentPage] = useState(1);

    const azure_url = "https://weddingsongapp-gsfbgncvbzdye5e9.canadaeast-01.azurewebsites.net"
    const local_url = "https://wedding-api.blustery.day"

    const handleInputChange = (e) => {
        setSongTitle(e.target.value);
    };

    function handleSubmit(event, song) {
        event.preventDefault();
        handleAddItem(song.uri, song.artist, song.title, song.image)
        handleClose()
    }

    const handleSearch = async (e) => {
        e.preventDefault()
        setLoading(true);
        try {
            const response = await axios.get(local_url + '/search', { params: { "trackName": songTitle } });
            console.log('Song search:', response.data);
            setSearchedSongs(response.data)
            setCurrentPage(1)
        } catch (error) {
            console.error('Error search song:', error);
            setMessage('Error searching for song', error);
        } finally {
            setLoading(false);
        }
    };

    const getDisplayedSongs = (songs, currentPage, pageSize) => {
        const startIndex = (currentPage - 1) * pageSize;
        return songs.slice(startIndex, startIndex + pageSize);
    };

    const displayedSongs = getDisplayedSongs(searchedSongs, currentPage, 5);

    return (
        <Modal show={show} onHide={() => !loading && handleClose()}>
            <Modal.Header closeButton>
                <Modal.Title>Add a new song</Modal.Title>
            </Modal.Header>
            <Form>
                <Form.Control
                    size="lg"
                    type="text"
                    placeholder="Search for song..."
                    name="songTitle"
                    value={songTitle}
                    onChange={handleInputChange}
                />
                <ListGroup>
                    {displayedSongs.map((searchedSong, index) => (
                        <ListGroup.Item key={index}>
                            <div className="d-flex align-items-center gap-2">
                                <Card className="m-5 border-0 shadow" style={styles.card}>
                                    <Card.Body style={styles.cardBody}>
                                        <Card.Img src={searchedSong.image} alt={searchedSong.title} style={styles.cardImage} />
                                        <Card.Text style={styles.cardText}>{searchedSong.title} - {searchedSong.artist}</Card.Text>
                                    </Card.Body>
                                </Card>
                                <Button type="primary" onClick={(event) => handleSubmit(event, searchedSong)}>
                                    Add
                                </Button>
                            </div>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
                {searchedSongs.length > 0 &&
                    <div className="d-flex justify-content-center">
                        <Pagination>
                            <Pagination.First onClick={() => setCurrentPage(1)} />
                            <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1} />
                            {Array.from({ length: Math.ceil(searchedSongs.length / 5) }).map((_, i) => (
                                <Pagination.Item key={i + 1} active={currentPage === i + 1} onClick={() => setCurrentPage(i + 1)}>
                                    {i + 1}
                                </Pagination.Item>
                            ))}
                            <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === Math.ceil(searchedSongs.length / 5)} />
                            <Pagination.Last onClick={() => setCurrentPage(Math.ceil(searchedSongs.length / 5))} />
                        </Pagination>
                    </div>}
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button type="primary" onClick={handleSearch}>
                        {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Search'}
                    </Button>
                </Modal.Footer>
                {message && <p>{message}</p>}
            </Form>
        </Modal>
    );
};

export default SearchModal;
