import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripHorizontal } from '@fortawesome/free-solid-svg-icons';
import Card from 'react-bootstrap/Card';


const styles = {
    card: {
        borderRadius: 25,
        padding: '1rem',
        width: 'auto',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column'
    },
    cardImage: {
        objectFit: 'cover',
        borderRadius: 10,
        width: 'auto',
        height: '100px'
    },
    cardBody: {
        display: 'flex',
        flexDirection: 'row',
        padding: '1rem',
        alignItems: 'center',
        gap: '1rem'
    },
    cardHeader: {
        textAlign: 'left',
        marginBottom: '1rem',
        fontSize: '26px' // Increase font size for card text
    },
    cardText: {
        fontSize: '30px' // Increase font size for card text
    }
};

const SongCard = ({ artist, song, albumArt, provided, callback, index, showDelete, uri }) => {
    const [width, setWidth] = useState('100%');

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1200) {
                setWidth('100%');
            } else if (window.innerWidth >= 900) {
                setWidth('80%');
            } else if (window.innerWidth >= 600) {
                setWidth('60%');
            } else {
                setWidth('60%');
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Set initial width

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps} className="d-flex align-items-center gap-2" style={{ width }}>
            <Card className="m-5 border-0 shadow" style={styles.card}>
                <Card.Body style={styles.cardBody}>
                    <FontAwesomeIcon icon={faGripHorizontal} size="3x" />
                    <Card.Img src={albumArt} alt={song} style={styles.cardImage} />
                    <Card.Text style={styles.cardText}>{song} - {artist}</Card.Text>
                </Card.Body>
            </Card>
            {showDelete ? (
                <button className="btn btn-danger" onClick={() => callback(index)}>
                    Delete
                </button>
            ) : (
                <button className="btn btn-primary" onClick={() => callback(uri, artist, song, albumArt)}>
                    Add
                </button>
            )}
        </div>
    );
};

export default SongCard;